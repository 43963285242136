<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  headerKey: String as PropType<string>,
  hideBelow: String as PropType<'sm' | 'md' | 'lg'>,
  hideAbove: String as PropType<'sm' | 'md' | 'lg'>,
  align: { type: String as PropType<'start' | 'center' | 'end' | undefined>, default: 'start' },
});
const { hideBelow, align } = toRefs(props);
// const emit = defineEmits(['sort']);
</script>

<template>
  <th
    scope="col"
    :class="[
      'px-3 py-3.5 text-left text-sm font-semibold text-neutral-500 dark:text-neutral-400',
      hideBelow ? hideBelow + ':table-cell hidden' : '',
      align ? `text-${align}` : 'text-start',
    ]"
  >
    <slot></slot>
    <!--    <a href="#" class="group inline-flex">-->
    <!--      <slot></slot>-->
    <!--      <span-->
    <!--        :class="[-->
    <!--          sortKey === headerKey-->
    <!--            ? ' text-neutral-500 dark:text-neutral-400 group-hover:text-neutral-700 dark:text-neutral-200'-->
    <!--            : 'invisible group-hover:visible group-focus:visible text-neutral-400 dark:text-neutral-500',-->
    <!--          'ml-2 flex-none rounded',-->
    <!--        ]"-->
    <!--      >-->
    <!--        <ChevronUpIcon v-if="sortDirection === -1 && sortKey === headerKey" class="h-5 w-5" aria-hidden="true" />-->
    <!--        <ChevronDownIcon v-else class="h-5 w-5" aria-hidden="true" />-->
    <!--      </span>-->
    <!--    </a>-->
  </th>
</template>
